var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
// Entrypoint file which will be exposed to end user
import * as SentryInitialize from "../../../../src/utils/SentryInitialize";
import { userContext } from "../helpers/userContext";
import { CustomTrackingMethods } from "../customTrackingMethods";
import { GTM } from "../googleTagManager";
import { Hotjar } from "../hotjar";
import { Wootric } from "../wootric";
import { Mixpanel } from "../mixpanel";
import { MixpanelTrackingPageView } from "../mixpanelTrackingPageView";
import { trackers as Trackers, routes } from "../helpers/utils/constants";
import { Avo } from "../avo";
import SplashLogger from "utils/SplashLogger";
import * as Sentry from "@sentry/browser";
import { ServerSideEvents } from "../serverSideEvents";
import { attachBufferDurationToProps } from "../helpers/utils";
import { GoogleAnalytics } from "../googleAnalytics";
import { get } from "utils/api";
// public class exposed to be used in FE projects directly like web-ui, school-ui, etc
// Sample code
/*
    const eventTrackers = new EventTrackers(trackers);
    await eventTrackers.build();
    eventTrackers.trackAnalyticsEvent(args..);
*/
SentryInitialize.init(WebCoreUIConfig.sentry.overrides.trackerModules.envSuffix);
var loggerName = "TrackersMain";
var EventTrackers = /** @class */ (function () {
    function EventTrackers(trackers) {
        var _this = this;
        this.initializeGtm = function () {
            var _a;
            try {
                // ToDo: This should ideally only be driven by GTM
                if (_this.trackers.includes(Trackers.GTM) || _this.trackers.includes(Trackers.SL_EVENT_TRACKERS)) {
                    var gtm = new GTM();
                    _this.trackToGtm = gtm.trackToGtm;
                    _this.trackTransactionEventToGtm = gtm.trackTransactionEventToGtm;
                }
            }
            catch (e) {
                SplashLogger.error(loggerName, e);
                Sentry.captureException(e, {
                    level: "error",
                    extra: {
                        analyticsId: (_a = window.trackerProperties) === null || _a === void 0 ? void 0 : _a.analyticsId
                    }
                });
            }
        };
        this.initializeGA = function () {
            var _a;
            try {
                if (_this.trackers.includes(Trackers.GA) && window.trackerProperties.userType != "student") {
                    var ga = new GoogleAnalytics();
                }
            }
            catch (e) {
                SplashLogger.error(loggerName, e);
                Sentry.captureException(e, {
                    level: "error",
                    extra: {
                        analyticsId: (_a = window.trackerProperties) === null || _a === void 0 ? void 0 : _a.analyticsId
                    }
                });
            }
        };
        this.initializeGAForStudent = function () {
            var _a;
            try {
                if (_this.trackers.includes(Trackers.GA) && window.trackerProperties.userType === "student") {
                    var ga = new GoogleAnalytics(_this.context);
                }
            }
            catch (e) {
                SplashLogger.error(loggerName, e);
                Sentry.captureException(e, {
                    level: "error",
                    extra: {
                        analyticsId: (_a = window.trackerProperties) === null || _a === void 0 ? void 0 : _a.analyticsId
                    }
                });
            }
        };
        this.initializeWootric = function () {
            var _a;
            try {
                if (_this.trackers.includes(Trackers.WOOTRIC)) {
                    var wootric = new Wootric();
                }
            }
            catch (e) {
                SplashLogger.error(loggerName, e);
                Sentry.captureException(e, {
                    level: "error",
                    extra: {
                        analyticsId: (_a = window.trackerProperties) === null || _a === void 0 ? void 0 : _a.analyticsId
                    }
                });
            }
        };
        this.initializeHotjar = function () {
            var _a;
            try {
                var hotjar = new Hotjar(_this.context);
                _this.hotjarIdentify = hotjar.identify;
                _this.hotjarSendEvent = hotjar.sendEvent;
            }
            catch (e) {
                SplashLogger.error(loggerName, e);
                Sentry.captureException(e, {
                    level: "error",
                    extra: {
                        analyticsId: (_a = window.trackerProperties) === null || _a === void 0 ? void 0 : _a.analyticsId
                    }
                });
            }
        };
        this.initializeMixpanelAndAvo = function () {
            var _a;
            try {
                if (_this.trackers.includes(Trackers.MIXPANEL)) {
                    var mixpanel = new Mixpanel();
                }
                // initialise avo even if trackers.MIXPANEL is not true. 
                // trackers.MIXPANEL can be undefined if analytics consent is rejected by user or if mixpanel was never included in html.slim rendered from web
                // In such cases window.sendEventViaAvo function should still be present in window so that flutter code does not break.
                var avo = new Avo();
            }
            catch (e) {
                SplashLogger.error(loggerName, e);
                Sentry.captureException(e, {
                    level: "error",
                    extra: {
                        analyticsId: (_a = window.trackerProperties) === null || _a === void 0 ? void 0 : _a.analyticsId
                    }
                });
            }
        };
        this.initializeMixpanelPageView = function () {
            var _a;
            try {
                if (_this.trackers.includes(Trackers.SL_EVENT_TRACKERS)) {
                    var mixpanelPageView = new MixpanelTrackingPageView();
                    if (_this.trackers.includes(Trackers.MIXPANEL_PAGE_VIEW)) {
                        mixpanelPageView.initalizeElementsForPageViewTracking();
                    }
                    _this.trackPageLoadEvents = mixpanelPageView.trackPageLoadEvents;
                }
            }
            catch (e) {
                SplashLogger.error(loggerName, e);
                Sentry.captureException(e, {
                    level: "error",
                    extra: {
                        analyticsId: (_a = window.trackerProperties) === null || _a === void 0 ? void 0 : _a.analyticsId
                    }
                });
            }
        };
        this.initializeSlEventTrackers = function () {
            var _a;
            try {
                if (_this.trackers.includes(Trackers.SL_EVENT_TRACKERS)) {
                    var customTrackingMethods = new CustomTrackingMethods(_this.context);
                    _this.trackAnalyticsEvent = customTrackingMethods.trackAnalyticsEvent;
                    _this.trackClevertapEvent = customTrackingMethods.trackClevertapEvent;
                }
            }
            catch (e) {
                SplashLogger.error(loggerName, e);
                Sentry.captureException(e, {
                    level: "error",
                    extra: {
                        analyticsId: (_a = window.trackerProperties) === null || _a === void 0 ? void 0 : _a.analyticsId
                    }
                });
            }
        };
        this.initializeServerSideEvents = function () {
            var trackServerSide = new ServerSideEvents();
            _this.trackServerSide = trackServerSide.trackServerSide;
            _this.trackEventsForGuestsCore = trackServerSide.trackEventsForGuests;
        };
        this.flushBufferedEvents = function () {
            var _a, _b, _c, _d, _e;
            var analyticsEvents = (_a = window.bufferedEvents) === null || _a === void 0 ? void 0 : _a.analyticsEvent;
            var guestEvents = (_b = window.bufferedEvents) === null || _b === void 0 ? void 0 : _b.guestEvents;
            var gtmEvents = (_c = window.bufferedEvents) === null || _c === void 0 ? void 0 : _c.gtmEvents;
            var gtmTransactionEvents = (_d = window.bufferedEvents) === null || _d === void 0 ? void 0 : _d.gtmTransactionEvents;
            var pageLoadEvents = (_e = window.bufferedEvents) === null || _e === void 0 ? void 0 : _e.pageLoadEvents;
            if (analyticsEvents && analyticsEvents.length > 0) {
                for (var _i = 0, analyticsEvents_1 = analyticsEvents; _i < analyticsEvents_1.length; _i++) {
                    var eventArgs = analyticsEvents_1[_i];
                    var props = attachBufferDurationToProps(eventArgs['props'], eventArgs['bufferTime'], eventArgs['bufferReason']);
                    _this.trackAnalyticsEvent(eventArgs['eventName'], props, eventArgs['sendGa'], eventArgs['callbackMethod']);
                }
                window.bufferedEvents.analyticsEvent = [];
            }
            if (gtmEvents && gtmEvents.length > 0) {
                for (var _f = 0, gtmEvents_1 = gtmEvents; _f < gtmEvents_1.length; _f++) {
                    var eventArgs = gtmEvents_1[_f];
                    _this.trackToGtm(eventArgs['dataLayer'], eventArgs['eventName'], eventArgs['category'], eventArgs['properties']);
                }
                window.bufferedEvents.gtmEvents = [];
            }
            if (gtmTransactionEvents && gtmTransactionEvents.length > 0) {
                for (var _g = 0, gtmTransactionEvents_1 = gtmTransactionEvents; _g < gtmTransactionEvents_1.length; _g++) {
                    var eventArgs = gtmTransactionEvents_1[_g];
                    _this.trackTransactionEventToGtm(eventArgs['a']);
                }
                window.bufferedEvents.gtmTransactionEvents = [];
            }
            if (pageLoadEvents && pageLoadEvents.length > 0) {
                _this.trackPageLoadEvents();
                window.bufferedEvents.pageLoadEvents = [];
            }
            if (guestEvents && guestEvents.length > 0) {
                for (var _h = 0, guestEvents_1 = guestEvents; _h < guestEvents_1.length; _h++) {
                    var eventArgs = guestEvents_1[_h];
                    attachBufferDurationToProps(eventArgs['props'], eventArgs['bufferTime'], eventArgs['bufferReason']);
                    _this.trackEventsForGuestsCore(eventArgs['eventName'], eventArgs['props'], eventArgs['meta']);
                }
                window.bufferedEvents.guestEvents = [];
            }
            // mark reason as undefined once used, as buffered events are now flushed.
            window.trackerModulesBufferReason = undefined;
        };
        this.flushHotjarBufferedEvents = function () {
            var _a, _b;
            var hotjarIdentifyEvents = (_a = window.bufferedEvents) === null || _a === void 0 ? void 0 : _a.hotjarIdentifyEvents;
            var hotjarTriggerEvents = (_b = window.bufferedEvents) === null || _b === void 0 ? void 0 : _b.hotjarTriggerEvents;
            if (hotjarIdentifyEvents && hotjarIdentifyEvents.length > 0) {
                for (var _i = 0, hotjarIdentifyEvents_1 = hotjarIdentifyEvents; _i < hotjarIdentifyEvents_1.length; _i++) {
                    var eventArgs = hotjarIdentifyEvents_1[_i];
                    _this.hotjarIdentify(eventArgs['clientProperties']);
                }
                window.bufferedEvents.hotjarIdentifyEvents = [];
            }
            if (hotjarTriggerEvents && hotjarTriggerEvents.length > 0) {
                for (var _c = 0, hotjarTriggerEvents_1 = hotjarTriggerEvents; _c < hotjarTriggerEvents_1.length; _c++) {
                    var eventArgs = hotjarTriggerEvents_1[_c];
                    _this.hotjarSendEvent(eventArgs['eventName']);
                }
                window.bufferedEvents.hotjarTriggerEvents = [];
            }
        };
        this.transientTrackAnalyticsEvent = function (_event, properties, sendGA, callbackMethod) {
            properties || (properties = {});
            properties = attachBufferDurationToProps(properties, new Date(), "userContextNotYetPrepared");
            _this.trackServerSide(_event, properties, callbackMethod, null);
        };
        this.initializeTransientTrackAnalyticsEvent = function () {
            _this.trackAnalyticsEvent = _this.transientTrackAnalyticsEvent;
            window.trackAnalyticsEventCore = _this.transientTrackAnalyticsEvent;
        };
        this.calculateSystemTimeDelay = function () { return __awaiter(_this, void 0, void 0, function () {
            var currentDate, response, data, serverTime, parsedServerTimeMs, timeDifferenceInMilliseconds, err_1;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 4, , 5]);
                        currentDate = new Date();
                        return [4 /*yield*/, get(routes.GET_SERVER_TIME)];
                    case 1:
                        response = _a.sent();
                        if (!(response.status === 200)) return [3 /*break*/, 3];
                        return [4 /*yield*/, response.json()];
                    case 2:
                        data = _a.sent();
                        serverTime = (data === null || data === void 0 ? void 0 : data.utc_m) == undefined ? data === null || data === void 0 ? void 0 : data.utc : data.utc_m;
                        parsedServerTimeMs = new Date(serverTime);
                        timeDifferenceInMilliseconds = currentDate.getTime() - parsedServerTimeMs.getTime();
                        window.systemTimeDifferenceInMs = timeDifferenceInMilliseconds;
                        _a.label = 3;
                    case 3: return [3 /*break*/, 5];
                    case 4:
                        err_1 = _a.sent();
                        console.error(err_1);
                        Sentry.captureException(err_1, {
                            level: "error",
                            tags: {
                                analyticsId: window.trackerProperties.analyticsId
                            }
                        });
                        return [3 /*break*/, 5];
                    case 5: return [2 /*return*/];
                }
            });
        }); };
        this.trackers = trackers;
    }
    EventTrackers.prototype.build = function () {
        return __awaiter(this, void 0, void 0, function () {
            var userContextResponse;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!window.trackerProperties) {
                            Sentry.captureMessage("Tracker properties not found on window object", {
                                level: "error",
                            });
                            return [2 /*return*/];
                        }
                        this.calculateSystemTimeDelay();
                        this.initializeServerSideEvents();
                        this.initializeGtm();
                        this.initializeGA();
                        this.initializeWootric();
                        this.initializeTransientTrackAnalyticsEvent();
                        this.initializeMixpanelPageView();
                        this.initializeMixpanelAndAvo();
                        this.flushBufferedEvents();
                        return [4 /*yield*/, userContext.getInstance()];
                    case 1:
                        userContextResponse = _a.sent();
                        if (userContextResponse) {
                            this.context = userContextResponse.params;
                        }
                        else {
                            Sentry.captureMessage('Unexpected UserContextResponse', "error");
                            return [2 /*return*/];
                        }
                        this.initializeGAForStudent();
                        this.initializeHotjar();
                        this.flushHotjarBufferedEvents();
                        this.initializeSlEventTrackers();
                        return [2 /*return*/];
                }
            });
        });
    };
    return EventTrackers;
}());
export { EventTrackers };
// init function to be used in web to initialize required methods on window object
export var initializeCustomTrackers = function (trackers, config) { return __awaiter(void 0, void 0, void 0, function () {
    var eventTrackers;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                eventTrackers = new EventTrackers(trackers);
                window.webCoreUiRuntimeConfigs = config;
                return [4 /*yield*/, eventTrackers.build()];
            case 1:
                _a.sent();
                return [2 /*return*/];
        }
    });
}); };
