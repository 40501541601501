import { pushEventsViaApiThrottler, initalizeDumpServiceApiThrottler } from "../helpers/pushEventsToDumpService";
import { checkAndSetAdwordsUtmParams } from "./utmParams";
import { attachBufferDurationToProps, isCMPVariantAndAnalyticsConsentTrue, isGuest, prepareAnalyticsProperties, trackCallbackMethodToSentry } from "../helpers/utils";
import * as Sentry from "@sentry/browser";
import SplashLogger from "utils/SplashLogger";
import { deleteCookie, getCookie, setCookieWithAge } from "utils/index";
import { COOKIE_NAMES } from "../helpers/utils/constants";
var loggerName = 'CustomTrackingMethods';
var CustomTrackingMethods = /** @class */ (function () {
    function CustomTrackingMethods(params) {
        var _this = this;
        this.trackAnalyticsEvent = function (_event, properties, sendGA, callbackMethod) {
            var _a, _b, _c, _d, _e;
            if (_this.params.userContextApiError) {
                properties || (properties = {});
                properties.mixpanelInstanceAvailable = !!window.mixpanel;
                properties.userContextApiError = _this.params.userContextApiError;
                properties = attachBufferDurationToProps(properties, new Date(), null);
                window.trackServerSide(_event, properties, callbackMethod);
                return;
            }
            if (!((_a = window.trackerProperties) === null || _a === void 0 ? void 0 : _a.analyticsEnabled)) {
                return;
            }
            properties = properties || {};
            try {
                var blacklistedEvents = _this.params.mixpanelBlacklistedEventsList;
                if (blacklistedEvents) {
                    window.mixpanel_blacklisted_events = blacklistedEvents;
                    if (window.mixpanel_blacklisted_events.indexOf(_event) !== -1) {
                        return;
                    }
                }
                var shouldTrackMetaSessionEvent = window.trackerProperties.metaSessionId !== undefined;
                if (!isGuest() && shouldTrackMetaSessionEvent) {
                    pushEventsViaApiThrottler(_event, properties);
                }
                // mixpanel method not defined through vendor code
                if (typeof (window.mixpanel) === 'undefined') {
                    var trackerMixpanelNotFound = getCookie(COOKIE_NAMES.trackerMixpanelNotFound);
                    if (isCMPVariantAndAnalyticsConsentTrue() && _this.params.user && !isGuest() && !trackerMixpanelNotFound) {
                        properties.sourceEventName = _event;
                        window.trackServerSide("Mixpanel Instance not found during event dispatch from FE library", properties);
                        setCookieWithAge(COOKIE_NAMES.trackerMixpanelNotFound, 'true');
                    }
                    return;
                }
                else {
                    deleteCookie(COOKIE_NAMES.trackerMixpanelNotFound);
                }
                properties = prepareAnalyticsProperties(_event, properties, _this.params);
                /**
                 *  Attach super props and send event to mixpanel
                 */
                // Mixpanel also has funda of super properties here, via the call to mixpanel.register.
                // We can use that as well to set default parameters to request.
                if (((_b = _this.params.user) === null || _b === void 0 ? void 0 : _b.userEventTrack) && (!_this.params.mixpanelBlockAllTypeListsForWeb || _this.params.mixpanelBlockAllTypeListsForWeb.length === 0)) {
                    try {
                        // Adding console logs additionally due to requirement from product team
                        console.group('EVENT DISPATCHED');
                        console.info(_event);
                        console.info(properties);
                        console.groupEnd();
                        SplashLogger.debug(loggerName, 'EVENT DISPATCHED', {
                            event: _event,
                            properties: properties
                        });
                    }
                    catch (err) {
                        console.error('SOMETHING WENT WRONG in logging -- trackAnalyticsEvent', err.message);
                    }
                    if (!window.getMixpanelInstance || typeof (window.getMixpanelInstance) != "function") {
                        SplashLogger.error(loggerName, "getMixpanelInstance not defined");
                        return;
                    }
                    var mixpanelInstance = window.getMixpanelInstance((_c = window.trackerProperties) === null || _c === void 0 ? void 0 : _c.analyticsId);
                    if (window.mixpanel_blocked_events.indexOf(_event) === -1) {
                        // This is to ensure that the event is always triggered even in cases where
                        // a redirect happens right after the triggering call.
                        _this.trackClevertapEvent(_event, properties);
                        if (_event === "Product Purchased Frontend") {
                            window.trackServerSide(_event, properties, null);
                            return;
                        }
                        mixpanelInstance.track(_event, properties);
                        if (_event === "Interacted Grade Selection") {
                            window.trackServerSide("Interacted Grade Selection BE", properties, null);
                        }
                    }
                }
            }
            catch (err) {
                SplashLogger.error(loggerName, {
                    msg: "Error in trackAnalyticsEvent",
                    event: _event,
                    properties: properties
                }, err);
                Sentry.captureException(err, {
                    extra: { event: _event, properties: properties, analyticsId: (_d = window.trackerProperties) === null || _d === void 0 ? void 0 : _d.analyticsId },
                    level: "error",
                });
            }
            // moved this code to finally block to ensure callback method is executed everytime without fail
            finally {
                _this.executeCallbackMethod(callbackMethod, _event, (_e = window.trackerProperties) === null || _e === void 0 ? void 0 : _e.analyticsId);
            }
        };
        this.trackClevertapEvent = function (event, properties) {
            var AllowedEvents = _this.params.clevertapAllowedEvents;
            // The first check means empty list returned by Clevertap.allowed_events
            // In this case, trackServerSide anyway and let BE handle the filtering
            if (AllowedEvents == "[]" || AllowedEvents.indexOf(event) !== -1) {
                window.trackServerSide(event, properties, null, true);
            }
        };
        this.params = params;
        initalizeDumpServiceApiThrottler(params);
        checkAndSetAdwordsUtmParams();
        window.trackAnalyticsEventCore = this.trackAnalyticsEvent;
        window.trackClevertapEvent = this.trackClevertapEvent;
    }
    CustomTrackingMethods.prototype.executeCallbackMethod = function (callbackMethod, event, analyticsId) {
        if (callbackMethod && typeof (callbackMethod) === "function") {
            try {
                trackCallbackMethodToSentry(callbackMethod, event, "executeCallbackMethod");
                callbackMethod();
            }
            catch (e) {
                if (WebCoreUIConfig.env === 'development' || WebCoreUIConfig.env === 'staging') {
                    SplashLogger.error(loggerName, 'Error in mixpanel event callback', e);
                }
                SplashLogger.error(loggerName, 'SOMETHING WENT WRONG -- 4');
                Sentry.captureException(e, {
                    tags: { analyticsId: analyticsId },
                    level: "error",
                });
            }
        }
    };
    return CustomTrackingMethods;
}());
export { CustomTrackingMethods };
