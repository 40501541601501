import SplashLogger from "./SplashLogger";
import * as Sentry from "@sentry/browser";
var loggerName = 'utils';
export var getRequestURL = function (request) { return new URL(request.url); };
export var getErrorInfo = function (err) {
    return {
        type: typeof err,
        name: err.name,
        message: err.message,
        stack: err.stack,
    };
};
export function getCookie(cookieName) {
    var _a;
    var name = cookieName + "=";
    var cookieArray = document.cookie.split(';');
    for (var i = 0; i < cookieArray.length; i++) {
        var cookie = cookieArray[i].trim();
        if (cookie.startsWith(name)) {
            var cookieValue = cookie.substring(name.length);
            try {
                // Decode the cookie value
                return decodeURIComponent(cookieValue);
            }
            catch (e) {
                // Report the error and return the raw value if decoding fails so that client can atleast use the value.
                SplashLogger.error(loggerName, "getCookie: Error decoding cookie ".concat(cookieName, ": "), e);
                Sentry.captureException(e, {
                    level: "error",
                    extra: {
                        cookieName: cookieName,
                        cookieValue: cookieValue,
                        analyticsId: (_a = window.trackerProperties) === null || _a === void 0 ? void 0 : _a.analyticsId,
                        cookie: document.cookie,
                    },
                });
                return cookieValue;
            }
        }
    }
    return "";
}
export function setCookieWithAge(name, value, maxAge) {
    if (maxAge === void 0) { maxAge = 31536000; }
    document.cookie = name + "=" + value + "; max-age=" + maxAge + "; path=/";
}
export function deleteCookie(name) {
    document.cookie = name + "=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT";
}
export function getAllCookies() {
    var decodedCookies = '';
    try {
        var rawCookies = document.cookie;
        var cookieArray = rawCookies.split(';');
        decodedCookies = cookieArray.map(function (cookie) {
            var equalsIndex = cookie.indexOf('=');
            if (equalsIndex > -1) {
                var name_1 = cookie.substring(0, equalsIndex).trim(); // Get the name
                var value = cookie.substring(equalsIndex + 1); // Get everything after the first '='
                if (name_1) {
                    try {
                        // Try to decode the cookie value
                        return "".concat(name_1, "=").concat(decodeURIComponent(value));
                    }
                    catch (error) {
                        // decoding fails
                        return "".concat(name_1, "=").concat(value);
                    }
                }
            }
            return '';
        }).filter(Boolean).join('; '); // Filter out invalid cases
    }
    catch (error) {
        SplashLogger.error(loggerName, 'Error retrieving cookies:', error);
        decodedCookies = '';
    }
    return decodedCookies;
}
